import React from "react";
import styled from "styled-components";

import Header from "./Header";
import WhyJP from "./WhyJP";
import SupportedBy from "./SupportedBy";
import MeetJP from "components/Home/MeetJP";
import FeaturedCouses from "components/Home/FeaturedCourses";
import { SubscribeBtn } from 'components/buttons';

import { TRIAL_DAYS } from "utils/constants";
import PuddinPops from "./PuddinPops";
import Footer from "components/_core/Footer";
import FinalWords from "components/Home/FinalWords";
import Proof from "./Proof";
import CourseModal from "components/Courses/CourseModal";

const CTA = styled(SubscribeBtn)`
    text-transform: none;

    padding: 13px 30px;
    width: 100%;

    max-width: 350px;
    
    border: 2px solid #fff;
    box-shadow: 0px 6px 0 0 #00000021;

    margin: 30px 0;
    font-size: 20px;

    div {
        font-style: italic;
        font-size: 15px;
    }
`;

const SatisfactionGuarantee = styled.p`
    font-weight: 500;
    color: #666;
    font-size: 14px;
    margin-top: -20px;
`;

const CTAButton = ({ whiteText }) => <>
    <CTA pill large>
        Start Improvising Now!
        {TRIAL_DAYS > 0 ? <div>Try 7 Days Free</div> : <div>Sign Up Now</div>}
    </CTA>
    <SatisfactionGuarantee style={whiteText ? { color: '#fff' }: {}}>100% Satisfaction Guarantee</SatisfactionGuarantee>
</>;

const LandingPage = ({
    heading,
    subHeading,
    testimonial,
    testimonialByline,
    videoId
}) => {

    return (
        <div>
            <Header
                title={heading}
                subtitle={subHeading}
                testimonial={testimonial}
                testimonialAuthor={testimonialByline}
                videoId={videoId}
                Button={CTAButton}
           />

           <WhyJP Button={CTAButton} />
           <Proof Button={CTAButton} />
           <SupportedBy />
           <PuddinPops Button={CTAButton} />
           <MeetJP Button={CTAButton} />
           <FeaturedCouses />
           <FinalWords />
           <Footer />
           <CourseModal />
        </div>
    );
};

export default LandingPage;
