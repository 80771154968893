import React, { useState } from 'react';

export default props => {
    const { sections, HeaderComponent, ItemsComponent, activeSection: activeSectionRedux, setActiveSection, internalState } = props;

    const [activeSectionState, setActiveSectionState] = useState(0);

    const handleClick = idx => {
        if (internalState) {
            return setActiveSectionState(idx);
        }

        setActiveSection(idx);
    }

    const activeSection = internalState ? activeSectionState : activeSectionRedux;

    return sections.map((section, i) => (
        <React.Fragment key={`section-${i}`}>
            <HeaderComponent active={i === activeSection} {...section} onClick={() => handleClick(i)} />
            {i === activeSection && <ItemsComponent {...section} />}
        </React.Fragment>
    ));
}