import React from 'react';
import styled from 'styled-components';
import HPHeroBG from 'img/homepage/hp-hero.jpg';
import { SubscribeBtn } from 'components/buttons';
import LogoCircle from '../_core/LogoCircle';
import HeroTestimonials from './HeroTestimonials';
import Container from 'components/_core/Container';
import { TRIAL_DAYS } from 'utils/constants';

const chinHeight = '100px';

const HeroContainer = styled.div`
    position: relative;
    min-height: 100vh;
    background-color: #fff;
`;

const HeroTop = styled.div`
    position: relative;
    background-color: #fff;

    background-position: top right;
    background-size: auto 100%;
    background-repeat: no-repeat;
    z-index: 1;

    @media screen and (max-width: 1200px) {
        background-position: top left 240px;
    }

    @media screen and (max-width: 530px) {
        background-position: top left 100px;
    }
`;

const HeroContent = styled(Container)`
    color: #000;
    padding: 100px 15px;
    min-height: calc(100vh - ${chinHeight});
    text-align: left;

    h1 {
        font-weight: 200;
        font-family: hero-new, sans-serif;
        font-size: 45px;
        margin: 30px 0 10px;
        line-height: 1.2em;

        strong {
            line-height: 1em;
            text-transform: uppercase;
        }
    }

    p {
        margin: 0;
        font-size: 18px;
        font-weight: 500;

        .teal {
            font-style: italic;
            font-weight: 600;
            color: ${props => props.theme.colors.teal};
        }
    }

    @media screen and (max-width: 530px) {
        text-align: center;

        h1 {
            font-size: 9vw;
        }
    }
`;

const CTA = styled(SubscribeBtn)`
    text-transform: none;

    padding: 13px 50px;


    border: 2px solid #fff;
    box-shadow: 0px 6px 0 0 #00000021;

    margin: 20px 0;
    font-size: 20px;

    div {
        font-style: italic;
        font-size: 15px;
    }
`;

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 530px) {
        margin: 0 auto;
    }
`;

const ListItem = styled.li`
    padding-left: 28px;
    margin: 7px 0;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;

    position: relative;
    text-align: left;
    font-weight: 600;

    &::before {
        content: '';
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: #47b74c;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
    &::after {
        content: '';
        height: 4px;
        width: 9px;
        margin-left: 4px;
        margin-top: -1px;
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%) rotate(-45deg);
    }
`;

const HeroChin = styled.div`
    height: ${chinHeight};
    position: relative;
    z-index: 3;
    background-color: #eee;
`;

const Hero = () => (
    <HeroContainer>
        <HeroTop style={{ backgroundImage: `url(${HPHeroBG})` }}>
            <HeroContent>
                <LogoCircle size="large" />
                <h1>
                    <strong>JPBouvet</strong>Method
                </h1>
                <p>Online Drum Courses for All Levels</p>
                <p>Specializing in Teaching <span className="teal">Improvisation, Flow, Soloing, Creativity</span></p>
                <CTA pill large>
                    Find Your Freedom
                    {TRIAL_DAYS > 0 ? <div>Start 7 Days Free</div> : <div>Sign Up Now</div>}
                </CTA>
                <List>
                    <ListItem>100% Satisfaction Guarantee</ListItem>
                    <ListItem>Step-by-Step Courses</ListItem>
                    <ListItem>Worldclass Instructor</ListItem>
                    <ListItem>1-on-1 Feedback</ListItem>
                    <ListItem>Exclusive Student Community</ListItem>
                    <ListItem>Renowned Guest Teachers</ListItem>
                </List>
            </HeroContent>
        </HeroTop>
        <HeroChin>
            <HeroTestimonials />
        </HeroChin>
    </HeroContainer>
);

export default Hero;