import React from 'react';
import { SubscribeBtn } from 'components/buttons';
import SaleBar from './SaleBar';
import styled from "styled-components";

const SatisfactionGuarantee = styled.p`
    font-weight: 500;
`;

const SeePricingBar = () => (
    <SaleBar>
        <SubscribeBtn large teal pill bold>See Pricing</SubscribeBtn>
        <SatisfactionGuarantee>100% Satisfaction Guarantee</SatisfactionGuarantee>
    </SaleBar>
);

export default SeePricingBar;
