import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InlineLoader from 'components/_core/InlineLoader';
import C from 'components/_core/Container';
import { fetchMyLearning } from 'redux/actions';
import { getEnvVar } from 'utils/get-env-var';

import CourseProgress from 'components/_core/CourseProgress';
import { forceCurrentVideoForNextLoad } from 'redux/actions';
import { Link } from 'react-router-dom';
import NextAMA from './NextAMA';

const Container = styled(C)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const RightNav = styled.div`
    display: flex;
    margin: 0 -10px;

    @media screen and (max-width: 550px) {
        display: none;
    }
`;

const NavItem = styled.div`
    margin: 0 10px;

    a { 
        font-size: 16px;
        color: #000;
        text-decoration: none;
        font-weight: 600;

        &:hover {
            color: ${props => props.theme.colors.teal};
        }
    }
`;

const MyLearningWrapper = styled.div`
    margin-top: 93px;

    h3 {
        margin-bottom: 10px;
    }
`;

const MyLearningHeader = styled.div`
    background-color: ${props => props.theme.colors.offWhite};
    font-size: 30px;
    padding: 30px 20px;
    margin: 0 -20px;

    ${Container} {
        color: #000;
        display: flex;
        justify-content: space-between;
    }
`;

const MyLearningMain = styled.div`
    background-color: #fff;
    padding: 30px 20px;
    margin: 0 -20px;

    ${Container} {
        color: #000;
    }
`;

const MyLearningWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row nowrap;


    @media screen and (max-width: 950px) {
        flex-flow: row wrap;
    }

    @media screen and (max-width: 550px) {
        > * {
            flex: 100% 0 0;
            margin: 20px 0;
        }
    }
`;

const Stats = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 20px;
    align-self: center;
    
    @media screen and (max-width: 950px) {
        flex: 100% 0 0;
        order: 3;
        margin: 20px 0;
    }

    @media screen and (max-width: 550px) {
        order: -1;
    }
`;

const Stat = styled.div`
    text-align: center;
    margin: 0 20px;
`;

const Value = styled.div`
    font-weight: 900;
    font-size: 50px;
`;

const Label = styled.div`
    font-weight: 500;
    font-size: 20px;
`;

// const RecentCourses = styled.ul`
//     list-style: none;
//     padding: 0;
// `;

// const RecentCourse = styled.li`
//     margin: 10px 0;
//     font-size: 16px;
//     cursor: pointer;
// `;

const LessonOfTheWeek = styled(Link)`
    font-size: 16px;
    cursor: pointer;
    color: #000;
    text-decoration: none;
`;

const MyLearning = () => {
    const dispatch = useDispatch();
    const error = useSelector(state => state.user.myLearning.error);
    const data = useSelector(state => state.user.myLearning.data);
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    useEffect(() => {
        if (isSubscriber) {
            dispatch(fetchMyLearning());
        }
    }, [dispatch, isSubscriber]);

    if (error || !isSubscriber) {
        return null;
    }

    if (!data) {
        return <MyLearningWrapper><InlineLoader /></MyLearningWrapper>;
    }

    return (
        <MyLearningWrapper>
            <MyLearningHeader>
                <Container>
                    <span>My Learning</span>

                    <RightNav>
                        <NavItem><a href="https://rhythmbot.app/" rel="noopener noreferrer" target="_blank">RhythmBot</a></NavItem>
                        <NavItem><a href="https://www.facebook.com/groups/improvisersclub" rel="noopener noreferrer" target="_blank">Improvisers Club</a></NavItem>
                    </RightNav>
                </Container>
            </MyLearningHeader>

            <MyLearningMain>
                <Container>
                    <MyLearningWrap>
                        <div>
                            <h3>Current Course Progress</h3>
                            <CourseProgress color="light" style={{ margin: 0 }} course={data.current_course} />
                        </div>
                        <Stats>
                            <Stat>
                                <Value>{data.completed_courses}</Value>
                                <Label>Courses Completed</Label>
                            </Stat>
                            <Stat>
                                <Value>{data.weekly_streak}</Value>
                                <Label>Week Streak</Label>
                            </Stat>
                        </Stats>
                        <div>
                            {/* <h3>Recently Added Courses</h3>
                            <RecentCourses>
                                {(data.recent_courses || []).map(c => {
                                    return (
                                        <RecentCourse key={`recent-course-${c.id}`} onClick={() => dispatch(openCourseModal(c.slug, c.name))}>{c.name}</RecentCourse>
                                    )
                                })}
                            </RecentCourses> */}
                            <h3 style={{ marginTop: '30px' }}>Lesson of the Week</h3>
                            <LessonOfTheWeek 
                                onClick={() => dispatch(forceCurrentVideoForNextLoad(data.latest_lesson.video_id))}
                                to={`${getEnvVar('ROOT_ROUTE')}/course/${data.latest_lesson.course_slug}`}>{data.latest_lesson.video_name}</LessonOfTheWeek>
                            <NextAMA date={data.next_ama_date} />
                        </div>
                    </MyLearningWrap>
                </Container>
            </MyLearningMain>
        </MyLearningWrapper>
    );
};

export default MyLearning;
