import React from 'react';
import styled from 'styled-components';

import YouTubeVideoThumb from './VideoThumb';

import C from "components/_core/Container";
import W from 'components/_core/Wrapper';
import { GridItem, ThreeGrid } from 'components/_core/grids';
import { Testimonial as T } from './TestimonialGrid';
import { SubscribeBtn as S } from 'components/buttons';
import ThumbPhoto from 'img/homepage/meet-jp-thumb.jpeg';

const Wrapper = styled(W)`
    background-color: #1c1c1c;
`;

const Container = styled(C)`
    padding: 30px 0;
`;
const Title = styled.h2`
    font-size: 50px;
    color: #fff;
    margin: 0 0 20px 0;
    font-weight: 200;
    text-align: center;
`;

const Grid = styled(ThreeGrid)`
    margin-top: 20px;
`;

const Testimonial = styled(T)`
    &:first-child {
        padding-top: 0;
    }

    .white {
        color: #fff;
    }
`;

const Achievments = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    color: ${props => props.theme.colors.teal};

    li {
        margin-bottom: 20px;
        padding-left: 20px;
        position: relative;

        &::before {
            content: "-";
            position: absolute;
            left: 0;
        }
    }
`;

const GridItemWithLines = styled(GridItem)`
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    @media screen and (max-width: 800px) and (min-width: 601px) {
        border: none;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }

`;

const SubscribeBtn = styled(S)`
    margin: 30px auto 0;
    border: 2px solid #fff;
    background: none;
    display: block;
    text-transform: none;

    &:hover:not(:disabled) {
        background-color: #fff;
        color: #000;
    }
`;

const SecondTestiGroup = styled(GridItem)`
    @media screen and (max-width: 450px) {
        display: none;  
    }   
`;

const MeetJP = ({ Button = () => <SubscribeBtn pill large>See Pricing</SubscribeBtn> }) => {
    return (
        <Wrapper>
            <Container>
                <Title>Meet Your Teacher: <strong>JP Bouvet</strong></Title>
                <YouTubeVideoThumb videoId="914832667" thumb={ThumbPhoto} isVimeo largePlayBtn backgroundThumb />

                <Grid stackPoint="600px">
                    <GridItem>
                        <Testimonial>
                            <p className="white">“Simply the best educator on improvisation there is.”</p>
                            <p className="teal">&mdash; Brandon G.</p>
                        </Testimonial>
                        <Testimonial>
                            <p className="white">“You’re just so insanely good at putting drum concepts into words and teaching.”</p>
                            <p className="teal">&mdash; Conner J.</p>
                        </Testimonial>
                        <Testimonial>
                            <p className="white">“You have the best teaching ability I’ve ever come across.”</p>
                            <p className="teal">&mdash; Adam W.</p>
                        </Testimonial>
                    </GridItem>

                    <GridItemWithLines>
                        <Achievments>
                            <li>1,000’s of in-person students taught in 34 countries</li>
                            <li>Steve Vai, Periphery, Zakk Wylde, Yngwie Malmsteen, Tosin Abasi, Nuno Bettencourt, Childish Japes, Greg Lapine</li>
                            <li>2011 Guitar Center Drum Off Champion</li>
                            <li>2011 Roland V-Drums Champion</li>
                            <li>2009-2011 Berklee College of Music</li>
                        </Achievments>
                    </GridItemWithLines>

                    <SecondTestiGroup>
                        <Testimonial>
                            <p className="white">“You are the best!”</p>
                            <p className="teal">&mdash; Bill Burr</p>
                        </Testimonial>
                        <Testimonial>
                            <p className="white">“Amazing drummer. Excellent teacher.”</p>
                            <p className="teal">&mdash; Darren King (Mutemath)</p>
                        </Testimonial>
                        <Testimonial>
                            <p className="white">“JP’s methods are like enter cheat codes in videos games.”</p>
                            <p className="teal">&mdash; J0intV3nture</p>
                        </Testimonial>
                    </SecondTestiGroup>

                </Grid>

                <div style={{ textAlign: 'center' }}>
                    <Button />
                </div>
            </Container>
        </Wrapper>
    );
};

export default MeetJP;
