import React from 'react';
import styled from 'styled-components';

import leftPhoto from 'img/landingpage/laptop-photo.png';
import numOne from 'img/landingpage/list-num-1.png';
import numTwo from 'img/landingpage/list-num-2.png';
import numThree from 'img/landingpage/list-num-3.png';

const Container = styled.div`
    background-color: #0a2f35;
    padding: 30px 0 60px;

    margin-top: -250px;
    padding-top: 280px;
    padding-bottom: 180px;
`;

const Wrapper = styled.div`
    
    max-width: 1200px;
    margin: 0 auto;
    margin-top: -40px;
    text-align: center;
`;

const Why = styled.div`
    color: #fff;
    font-size: 20px;
    margin-top: 50px;
    padding: 0 15px;

    h2 {
        font-size: 40px;
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 950px) {
    
        flex-direction: column;
    }
`;

const LeftPhoto = styled.div`
    flex-basis: 50%;
    padding-right: 30px;

    img {
        width: 100%;
        max-width: 500px;
    }
`;

const RightList = styled.div`
    flex-basis: 50%;
`;

const ListItem = styled.div`
    margin: 30px 0;
    display: flex;
    max-width: 500px;
`;

const ListNum = styled.img`
    margin-right: 20px;
    width: 75px;
    height: 75px;
`;

const ListContent = styled.div`
    text-align: left;

    h3 {
        margin: 0;
    }

    p {
        margin: 0;
        font-size: 16px;
    }
`;


const WhyJP = ({ Button }) => {
    return (
        <Container>
            <Wrapper>
                <Button whiteText={true} />

                <Why>
                    <h2>Why JP Bouvet Method?</h2>

                    <Flex>
                        <LeftPhoto>
                            <img src={leftPhoto} alt="JP Bouvet Method Website Screen Shots" />
                        </LeftPhoto>
                        <RightList>
                            <ListItem>
                                <ListNum src={numOne} alt="1" />
                                <ListContent>
                                    <h3><strong>PROVEN</strong> method</h3>
                                    <p>The most comprehensive, coherent, and effective approach to learning improvisation anywhere on the internet.</p>
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListNum src={numTwo} alt="2" />
                                <ListContent>
                                    <h3>Professional Coaching</h3>
                                    <p>Personalized guidance, coaching, and custom-tailored feedback from world-class professional drummers including JP.</p>
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListNum src={numThree} alt="3" />
                                <ListContent>
                                    <h3>Supportive Community</h3>
                                    <p>Create accountability and feel the upward spiral of encouragement and support from fellow students.</p>
                                </ListContent>
                            </ListItem>
                        </RightList>
                    </Flex>
                </Why>
            </Wrapper>
        </Container>
    )
};

export default WhyJP;
