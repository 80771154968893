import { Testimonial } from 'components/Home/TestimonialGrid';
import YouTubeVideoThumb from 'components/Home/VideoThumb';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ThumbPhoto from 'img/homepage/meet-jp-thumb.jpeg';
import starsImg from 'img/landingpage/stars.png';
import leftPhoto from 'img/landingpage/hero-left.jpg';
import rightPhoto from 'img/landingpage/hero-right.jpg';
import arrowRight from 'img/landingpage/arrow-right.png';
import arrowLeft from 'img/landingpage/arrow-left.png';
import logo from 'img/landingpage/logo.png';

const Logo = styled.img`
    width: 200px;
    margin-bottom: 40px;
`;

const Container = styled.div`
    background-color: #fff;
    margin: 0;
    padding: 30px 0;
`;

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
`;

const Title = styled.div`
    padding: 30px 15px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;

    h1 {
        font-size: 5rem;
        margin: 0;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 1.2em;
    }

    @media screen and (max-width: 950px) {
        h1 {
            font-size: 4rem;
        }
    
    }

    @media screen and (max-width: 700px) {
        h1 {
            font-size: 3rem;
        }
    }

    @media screen and (max-width: 450px) {
        h1 {
            font-size: 2.3rem;
        }
    }
`;

const ContentWrap = styled.div`
    position: relative;
`;

const Content = styled.div`
    padding: 0 15px 40px 15px;
    text-align: center;
    max-width: 420px;
    margin: 0 auto;

    h2 {
        font-size: 1.5rem;
        margin: 0;
        font-weight: 400;
    }
`;

const HeroPhoto = styled.img`
    position: absolute;
    top: 0;
    width: 300px;
    
    @media screen and (min-width: 1200px) {
        border-radius: 30px;
    }

    @media screen and (max-width: 1150px) {
        width: 200px;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -50px;
    }

    @media screen and (max-width: 950px) {
        display: none;
    }
`;


const LeftPhoto = styled(HeroPhoto)`
    left: 0;
`;

const RightPhoto = styled(HeroPhoto)`
    right: 0;
`;

const HeroArrow = styled.img`
    position: absolute;
    top: 0;
    width: 70px;

    @media screen and (max-width: 950px) {
        display: none;
    }
`;

const ArrowLeft = styled(HeroArrow)`
    left: 310px;
    top: 100px;

    @media screen and (max-width: 1150px) {
        left: 210px;
    }
`;

const ArrowRight = styled(HeroArrow)`
    right: 310px;
    top: 150px;

    @media screen and (max-width: 1150px) {
        right: 210px;
    }
`;

const VideoWrap = styled.div`
    max-width: 850px;
    margin: 0 auto;
`;

const Header = ({
    title,
    subtitle,
    testimonial,
    testimonialAuthor,
    videoId,
    Button
}) => {
    return (
        <Container>
            <Wrapper>
                <Link to="/"><Logo src={logo} alt="JP Bouvet Method Logo" /></Link>
                <Title>
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </Title>
                <ContentWrap>
                    <Content>
                        <h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
                        <Button />
                        <Testimonial>
                            <img src={starsImg} width="110" alt="Five starts" />
                            <p dangerouslySetInnerHTML={{ __html: testimonial }} />
                            <p className="teal">&mdash; {testimonialAuthor}</p>
                        </Testimonial>

                        <LeftPhoto src={leftPhoto} alt="JP Bouvet playing with Gen Axe" />
                        <RightPhoto src={rightPhoto} alt="JP Bouvet performing at a clinic" />
                        <ArrowLeft src={arrowLeft} alt="Left arrow" />
                        <ArrowRight src={arrowRight} alt="Right arrow" />
                    </Content>
                    
                    <VideoWrap>
                        <YouTubeVideoThumb videoId={videoId} thumb={ThumbPhoto} isVimeo largePlayBtn backgroundThumb />
                    </VideoWrap>
                </ContentWrap>
            </Wrapper>
        </Container>
    );
};

export default Header;
