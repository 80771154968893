import React from 'react';

import Button from 'components/_core/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SaleBar from './SaleBar';


const ViewCoursesBar = () => (
    <SaleBar showForSubscribers={true}>
        <Button as={Link} to="/courses" large teal pill bold>View All Courses</Button>
    </SaleBar>
);

export default ViewCoursesBar;
