export const ActionTypes = {
    TOGGLE_LIST_VIEW: 'TOGGLE_LIST_VIEW',
    OPEN_COURSE_MODAL: 'OPEN_COURSE_MODAL',
    CLOSE_COURSE_MODAL: 'CLOSE_COURSE_MODAL',
    PLAY_COURSE_PREVIEW: 'PLAY_COURSE_PREVIEW',
    STOP_COURSE_PREVIEW: 'STOP_COURSE_PREVIEW',
    FETCH_COURSE: 'FETCH_COURSE',
    FETCH_COURSES: 'FETCH_COURSES',
    FETCH_CATEGORIES: 'FETCH_CATEGORIES',
    COURSE_PROGRESS: 'COURSE_PROGRESS',
    TOGGLE_FAVORITES_VIEW: 'TOGGLE_FAVORITES_VIEW',
    TOGGLE_VIDEO_COMPLETE: 'TOGGLE_VIDEO_COMPLETE',
    TOGGLE_VIDEO_FAVORITE: 'TOGGLE_VIDEO_FAVORITE',
    SET_VIDEO_POSITION: 'SET_VIDEO_POSITION',
    SET_CURRENT_VIDEO: 'SET_CURRENT_VIDEO',
    FORCE_SET_CURRENT_VIDEO: 'FORCE_SET_CURRENT_VIDEO',
    CLEAR_CURRENT_VIDEO: 'CLEAR_CURRENT_VIDEO',
    SET_COURSE_DETAILS_TAB: 'SET_COURSE_DETAILS_TAB',
    TOGGLE_COURSE_OUTLINE: 'TOGGLE_COURSE_OUTLINE',
    USER_CURRENT_COURSE: 'USER_CURRENT_COURSE',
    SET_COURSE_OUTLINE_ACCORDIAN_SECTION: 'SET_COURSE_OUTLINE_ACCORDIAN_SECTION',
    SET_FILTER: 'SET_FILTER',
    RESET_FILTERS: 'RESET_FILTERS',
    TOGGLE_COURSE_SEARCH: 'TOGGLE_COURSE_SEARCH',
    FETCH_MODAL_COURSE: 'FETCH_MODAL_COURSE',
    COPY_MODAL_COURSE: 'COPY_MODAL_COURSE',
    TOGGLE_LOGIN_MODAL: 'TOGGLE_LOGIN_MODAL',
    TOGGLE_JOIN_MODAL: 'TOGGLE_JOIN_MODAL',
    SET_JOIN_MODAL_SCREEN: 'SET_JOIN_MODAL_SCREEN',
    CREATE_ACCOUNT: 'CREATE_ACCOUNT',
    LOGIN: 'LOGIN',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    VALIDATE_RESET_PASSWORD_TOKEN: 'VALIDATE_RESET_PASSWORD_TOKEN',
    RESET_PASSWORD: 'RESET_PASSWORD',
    CONFIRM_EMAIL: 'CONFIRM_EMAIL',
    LOGOUT: 'LOGOUT',
    VALIDATE_JWT: 'VALIDATE_JWT',
    SET_JOIN_PLAN: 'SET_JOIN_PLAN',
    SUBSCRIBE: 'SUBSCRIBE',
    SAVE_PROFILE: 'SAVE_PROFILE',
    SET_PROFILE_EDITING_SECTION: 'SET_PROFILE_EDITING_SECTION',
    USER_SUBSCRIPTIONS: 'USER_SUBSCRIPTIONS',
    CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
    UPGRADE_SUBSCRIPTION: 'UPGRADE_SUBSCRIPTION',
    RESUME_SUBSCRIPTION: 'RESUME_SUBSCRIPTION',
    FETCH_CURRICULUM: 'FETCH_CURRICULUM',
    SET_CURRICULUM_LINE_POS: 'SET_CURRICULUM_LINE_POS',
    FETCH_REFERRAL_CODE: 'FETCH_REFERRAL_CODE',
    VALIDATE_REFERRAL_CODE: 'VALIDATE_REFERRAL_CODE',
    FETCH_FEATURED_COURSES: 'FETCH_FEATURED_COURSES',
    FETCH_MY_LEARNING: 'FETCH_MY_LEARNING',
    FETCH_MY_FAVORITES: 'FETCH_MY_FAVORITES',
    FETCH_CONTENT_STATS: 'FETCH_CONTENT_STATS',
    FETCH_USER_DOWNLOAD_DATA: 'FETCH_USER_DOWNLOAD_DATA',
    DOWNLOAD_VIDEO: 'DOWNLOAD_VIDEO',
    ADD_PROGRESS_POST: 'ADD_PROGRESS_POST',
    FETCH_PROGRESS_POSTS: 'FETCH_PROGRESS_POSTS',
    SET_MY_LEARNING_VIEW: 'SET_MY_LEARNING_VIEW',
    FETCH_ACTIVE_SALE: 'FETCH_ACTIVE_SALE',
    SET_EMAIL_FOR_IMPACT: 'SET_EMAIL_FOR_IMPACT',
    IMPACT_EMAIL_SENT: 'IMPACT_EMAIL_SENT',
    SET_IMPACT_CLICK_ID: 'SET_IMPACT_CLICK_ID'
};

export const LEVEL_MAPPING = {
    all_levels: 'All Levels',
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    advanced: 'Advanced'
};

export const CATEGORY_MAPPING = {
    'drum-lessons': 'Drum Lessons',
    'grooves': 'Grooves',
    'sdrt': 'SDRT',
    'fills': 'Fills',
    'independence': 'Independence',
    'rudimens': 'Rudiments',
    'ghost-notes': 'Ghost Notes',
    'soloing': 'Soloing',
    'jazz': 'Jazz',
    'double-bass': 'Double Bass',
    'song-breakdowns': 'Song Breakdowns',
    'play-alongs': 'Play Alongs',
    'interviews': 'Interviews',
    'ask-me-anything': 'Ask Me Anything',
    'talking': 'Talking'
};

export const PLAN_MAPPING = {
    'monthly': 'Monthly',
    'annual': 'Annual'
};

export const PLAN_PRICING = {
    'monthly': 29.99,
    'annual': 299.99
};

export const TRIAL_DAYS = 7;


